<template>
	<el-dialog title="修改登录账号" class="dialog-header-line" :visible.sync="visible" width="560px">
		<el-form size="small" ref="editForm" class="edit-form-box"
			label-position="left"
			:model="form" :rules="rules">
			<el-form-item prop="user_name">
				<div class="msg-info">1年最多修改一次，请谨慎提交。</div>
				<el-input placeholder="请输入新的用户名" v-model="form.user_name" show-word-limit maxlength="16"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button class="btn" size="small" @click="visible = false">取消</el-button>
			<el-button class="btn" size="small" @click="sumbitBtn" type="danger">确定</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data(){
			return {
				visible:false,
				callFn:null,
				rules:{
					user_name:[{ validator:(rule,val,call)=>{
						if(!val){
							call(new Error("用户名不能为空"))
						}else{
							call()
						}
					}}]
				},
				form:{
					user_name:""
				}
			}
		},
		created(){
			
		},
		mounted(){
			
		},
		methods: {
			sumbitBtn(){
				this.$refs.editForm.validate((valid)=>{
					if(valid){
						this.$axios.putJSON("/base/user/name",this.form).then((res) => {
						  if (res.code == 200) {
								this.$message.success("修改成功");
								this.visible = false;
						    this.callFn && this.callFn(this.$utils.cloneDeep(this.form))
						  }
						});
					}
				})
			},
			open(data,call){
				this.form.user_name = '';
				this.callFn = call;
				this.visible = true;
			}
		},
	}
</script>

<style scoped lang="scss">
.edit-form-box{
	padding-left:20px;
	padding-right:20px;
}
.msg-info{
	color: #666666;
	margin-bottom: 20px;
}
.dialog-footer{
	text-align: center;
	.btn{
		width:80px;
	}
}
</style>
