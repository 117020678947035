<template>
  <div id="homeIndex">
    <!-- 基础信息 -->
    <div class="userMsg">
      <div class="imgs">
        <img :src="userAvatar" class="cursor" @click="imgChangeBtn" alt="" />
        <div class="edit-btn" @click="editSignBtn">修改资料</div>
      </div>
      <div class="msg">
        <div class="header-info-flex">
          <span class="names">
            <el-tag size="small">{{ userInfo.user_id }}</el-tag>
            {{
              userInfoData.nick_name ||
                userInfo.user_name ||
                userInfo.user_mobile
            }}
            <el-popover placement="right" width="200" trigger="click">
              <img
                v-if="showImgs"
                width="200px"
                height="200px"
                :src="showImgs"
                alt=""
              />
              <span @click="showSrc" slot="reference" class="iconsd">
                <svg width="16" height="16" viewBox="0 0 48 48" fill="none">
                  <path
                    d="M20 6H6V20H20V6Z"
                    fill="#0088fe"
                    stroke="#0088fe"
                    stroke-width="4"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20 28H6V42H20V28Z"
                    fill="#0088fe"
                    stroke="#0088fe"
                    stroke-width="4"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M42 6H28V20H42V6Z"
                    fill="#0088fe"
                    stroke="#0088fe"
                    stroke-width="4"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M29 28V42"
                    stroke="#0088fe"
                    stroke-width="4"
                    stroke-linecap="butt"
                  />
                  <path
                    d="M41 28V42"
                    stroke="#0088fe"
                    stroke-width="4"
                    stroke-linecap="butt"
                  />
                </svg>
              </span>
            </el-popover>
          </span>
          <div>
            <span class="people" @click="invitedBtn(1)">
              <svg
                width="14"
                height="14"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" fill="white" fill-opacity="0.01" />
                <path
                  d="M19 20C22.866 20 26 16.866 26 13C26 9.13401 22.866 6 19 6C15.134 6 12 9.13401 12 13C12 16.866 15.134 20 19 20Z"
                  fill="#4387f8"
                  stroke="#4387f8"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 40.8V42H34V40.8C34 36.3196 34 34.0794 33.1281 32.3681C32.3611 30.8628 31.1372 29.6389 29.6319 28.8719C27.9206 28 25.6804 28 21.2 28H16.8C12.3196 28 10.0794 28 8.36808 28.8719C6.86278 29.6389 5.63893 30.8628 4.87195 32.3681C4 34.0794 4 36.3196 4 40.8Z"
                  fill="#4387f8"
                  stroke="#4387f8"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M38 13V25V13Z"
                  fill="#4387f8"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M32 19H44H32Z"
                  fill="#4387f8"
                />
                <path
                  d="M38 13V25M32 19H44"
                  stroke="#4387f8"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              邀请好友</span
            >
            <span class="people" @click="invitedBtn(2)">
              <svg
                width="14"
                height="14"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" fill="white" fill-opacity="0.01" />
                <path
                  d="M19 20C22.866 20 26 16.866 26 13C26 9.13401 22.866 6 19 6C15.134 6 12 9.13401 12 13C12 16.866 15.134 20 19 20Z"
                  fill="#4387f8"
                  stroke="#4387f8"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 40.8V42H34V40.8C34 36.3196 34 34.0794 33.1281 32.3681C32.3611 30.8628 31.1372 29.6389 29.6319 28.8719C27.9206 28 25.6804 28 21.2 28H16.8C12.3196 28 10.0794 28 8.36808 28.8719C6.86278 29.6389 5.63893 30.8628 4.87195 32.3681C4 34.0794 4 36.3196 4 40.8Z"
                  fill="#4387f8"
                  stroke="#4387f8"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M38 13V25V13Z"
                  fill="#4387f8"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M32 19H44H32Z"
                  fill="#4387f8"
                />
                <path
                  d="M38 13V25M32 19H44"
                  stroke="#4387f8"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              邀请租户</span
            >
          </div>
        </div>
        <div class="mgSelf">
          <div>
            <span>个性签名：</span>
            <span>
              {{ userInfoData.signature || "--"
              }}<i class="el-icon-edit-outline icons" @click="editSignBtn"></i>
            </span>
          </div>
          <div>
            <!-- <el-tag size="mini" type="danger" style="margin-right: 8px">主企业</el-tag> -->
            <span @click="goToPage({ company_id: adminInfo.id })" class="cursor"
              >{{ adminInfo.name }}<i class="el-icon-arrow-right iconq"></i>
            </span>
          </div>
        </div>
        <div class="flexBu">
          <div>
            <span>登录账号：</span>
            <span
              >{{ userInfo.user_name || userInfo.user_mobile
              }}<i
                class="el-icon-edit-outline icons"
                @click="editAccountBtn"
              ></i>
            </span>
          </div>
          <div>
            <!-- <span class="qiye" @click="myCompanyBtn">我的企业列表 </span> -->
          </div>
        </div>
        <div class="flexBu">
          <div class="ask">
            <template v-if="userInfo.user_mobile">
              <div>
                <i class="el-icon-mobile"></i>
              </div>
              <span>{{ userInfo.user_mobile }}</span>
            </template>
            <template v-if="userInfo.user_email">
              <div>
                <i class="el-icon-message"></i>
              </div>
              <span>{{ userInfo.user_email || "--" }}</span>
            </template>
          </div>
          <div>
            <div class="others">
              <span>上次登录：{{ loginInfo.login_date }}</span>
              <span>IP地址：{{ loginInfo.login_ip }}</span>
            </div>
            <div class="others">
              <span>终端：{{ uuid }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录验证 -->
    <el-dialog
      title="工作台首次登录须修改密码"
      :visible.sync="loginCode.showLog"
      width="550px"
    >
      <div style="margin: 0 10px">
        <el-form
          size="small"
          ref="changeForm"
          :model="loginCode.datas"
          :rules="loginRules"
        >
          <el-form-item prop="first_pwd">
            <el-input
              clearable
              show-password
              style="width: 400px"
              v-model="loginCode.datas.first_pwd"
              placeholder="初始密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              clearable
              show-password
              style="width: 400px"
              v-model="loginCode.datas.password"
              placeholder="新密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password_confirm">
            <el-input
              clearable
              show-password
              style="width: 400px"
              size="small"
              v-model="loginCode.datas.password_confirm"
              placeholder="确认新密码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-row slot="footer">
        <el-col align="center">
          <el-button
            size="small"
            style="width: 88px"
            @click="loginCode.showLog = false"
            >取 消</el-button
          >
          <el-button
            size="small"
            style="width: 88px"
            type="primary"
            @click="sumbitFormData"
            >确 定</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>

    <!-- 修改签名 -->
    <editSign ref="editSign"></editSign>

    <!-- 修改头像 -->
    <editAvatar ref="editAvatar"></editAvatar>

    <!-- 修改账号 -->
    <editAccount ref="editAccount"></editAccount>
  </div>
</template>

<script>
import { getFingerprint } from "@/assets/js/finger.js";
import editSign from "@/views/mySelf/components/editSign.vue";
import editAvatar from "@/views/mySelf/components/editAvatar.vue";
import editAccount from "@/views/mySelf/components/editAccount.vue";
import eventBus from "@/server/eventBus.js";
export default {
  data() {
    let _this = this;
    let loginRules = {
      first_pwd: [
        {
          trigger: "blur",
          validator: (rules, value, call) => {
            if (!value) {
              call(new Error("请输入初始密码"));
            } else {
              call();
            }
          },
        },
      ],
      password: [
        {
          trigger: "blur",
          validator: (rules, value, call) => {
            let regPwd = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}/;
            if (!value) {
              call(new Error("请输入新密码"));
            } else if (!regPwd.test(value)) {
              call(new Error("密码须包含数字和字母"));
            } else {
              call();
            }
          },
        },
      ],
      password_confirm: [
        {
          trigger: "blur",
          validator: (rules, value, call) => {
            let regPwd = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}/;
            if (!value) {
              call(new Error("请输入确认密码"));
            } else if (_this.loginCode.datas.password != value) {
              call(new Error("两次密码不相同，请重新输入"));
            } else if (!regPwd.test(value)) {
              call(new Error("确认密码须包含数字和字母"));
            } else {
              call();
            }
          },
        },
      ],
    };

    return {
      showImgs: null,
      adminInfo: {},
      staffInfo: {},
      loginInfo: {},
      userAvatar: "",
      userInfo: {},
      userInfoData: {},
      loginRules,
      loginCode: {
        showLog: false,
        company_id: null,
        step: 1,
        datas: {
          company_id: "",
          first_pwd: "",
          password: "",
          password_confirm: "",
        },
        open: (val) => {
          const login = this.loginCode;
          login.step = 1;
          login.datas.first_pwd = "";
          login.datas.password = "";
          login.datas.password_confirm = "";
          login.company_id = val;
          login.showLog = true;
        },
      },
      uuid:'',
    };
  },
  components: {
    editAvatar,
    editSign,
    editAccount,
  },
  created() {
    getFingerprint().then((res) => {
      this.uuid = res;
    })
    this.getUserInfo();
  },
  mounted() {
    //监听主公司变化
    eventBus.$on("mainCompany", (data) => {
      this.adminInfo.id = data.company_id;
      this.adminInfo.name = data.company_name;
    });
  },

  methods: {
    showSrc() {
      this.ids = Math.random()
        .toString(36)
        .slice(2);
      let content =
        location.origin + "/h5/#/scan?scan_mode=login&scan_id=" + this.ids;
      this.$axios.post("/base/user/cache/token", {
        client_id: this.ids,
      });
      this.$axios
        .post("/ocr/generate_qrcode", {
          img_size: "200",
          content: content,
        })
        .then((res) => {
          this.showImgs = res.data;
        });
    },
    myCompanyBtn() {
      let companyList = document.getElementById("companyList");
      if (companyList) {
        companyList.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }
    },
    sumbitFormData() {
      //用户首次登录工作台修改密码
      this.$refs.changeForm.validate((res) => {
        if (res) {
          let param = {
            ...this.loginCode.datas,
            company_id: this.loginCode.company_id,
          };
          this.$axios
            .postJSON("/base/user/company/resetpwd", param)
            .then((res) => {
              if (res.code == 200) {
                this.goToPage({ company_id: this.loginCode.company_id });
              }
            });
        }
      });
    },
    imgChangeBtn() {
      this.$refs.editAvatar.open({ img_url: this.userAvatar }, (data) => {
        this.userAvatar = data.img_url;
      });
    },
    editAccountBtn() {
      this.$refs.editAccount.open(
        { user_name: this.userInfo.user_name || this.userInfo.user_mobile },
        (data) => {
          this.userInfo.user_name = data.user_name;
        }
      );
    },
    invitedBtn(type) {
      //邀请好友或租户
      if (type == 1) {
        //邀请好友
        this.$router.push({
          path: "/mySelf/invitedUser",
        });
      } else if (type == 2) {
        //邀请租户
        this.$router.push({
          path: "/mySelf/invitedCompany",
        });
      }
    },
    editSignBtn() {
      let params = {
        nick_name:
          this.userInfoData.nick_name ||
          this.userInfo.user_name ||
          this.userInfo.user_mobile,
        signature: this.userInfoData.signature,
      };
      this.$refs.editSign.open(params, (data) => {
        this.userInfoData.nick_name = data.nick_name;
        this.userInfoData.signature = data.signature;
      });
    },
    getUserInfo() {
      this.$axios.get("/base/user/login_info").then((res) => {
        if (res.code == 200) {
          let data = res.data;
          this.userAvatar = `https://avatar.${VUE_APP_API_URL}/user/avatar/${data.user_id}`;
          this.staffInfo = data.staff;
          this.adminInfo = data.company;
          this.loginInfo = data.login_log;
          this.userInfo = data;
          this.$store.commit("loginInfo", JSON.parse(JSON.stringify(res.data)));
        }
      });
      this.$axios.get("/base/user/info").then((res) => {
        if (res.code == 200) {
          let data = res.data;
          this.userInfoData = data;
        }
      });
    },
    goToPage(item) {
      this.$axios
        .postJSON("/base/user/login/company", {
          company_id: Number(item.company_id),
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.company_id) {
              this.loginCode.open(res.data.company_id);
            } else {
              if (process.env.NODE_ENV === "production") {
                sessionStorage.setItem("AccessToken", res.data.AccessToken);

                localStorage.setItem(
                  "time_token",
                  res.data.AccessExpire * 1000
                );
                localStorage.setItem("loading", "going");
              } else {
                localStorage.setItem("AccessToken", res.data.AccessToken);
              }
              window.open("/main/index");
              // window.open(window.location.origin + "/platform");
            }
          }
        });
    },
    query() {},
  },
};
</script>

<style lang="scss" scoped>
// .change-avatar-box{
// 	display: flex;
// 	.left{
// 		width:300px;
// 		padding-left: 48px;
// 		padding-top:80px;
// 		.left-title{
// 			font-size: 14px;
// 			color:#666666;
// 			position: relative;
// 			&::before{
// 				content:"";
// 				display: block;
// 				width:4px;
// 				height: 16px;
// 				background-color:#F74848;
// 				position: absolute;
// 				left:-10px;
// 				top:0px;
// 			}
// 		}
// 		.photo-box{
// 			margin-top:20px;
// 			.img-info{
// 				color:#666666;
// 				font-size: 12px;
// 				margin-top:12px;
// 			}
// 		}
// 	}
// 	.right{
// 		text-align: center;
// 		width:300px;
// 		.right-title{
// 			color:#666;
// 		}
// 		.line-boxs{
// 			border-left:1px solid #EFEFEF;
// 		}
// 		.avatar-box{
// 			margin-top: 16px;
// 			&.mt40{
// 				margin-top:40px;
// 			}
// 			.img-avatar{
// 				margin:0 auto 12px;
// 				&.big{
// 					width: 120px;
// 					height: 120px;
// 				}
// 				&.small{
// 					width:64px;
// 					height: 64px;
// 				}
// 			}
// 			.img-info{
// 				color: #666666;
// 				font-size: 12px;
// 			}
// 		}
// 	}
// }
.dialog-footer {
  text-align: center;
  .btn {
    width: 80px;
  }
}
#homeIndex {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  .userMsg {
    margin-top: 40px;
    height: 136px;
    background-color: #fff;
    display: flex;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    .imgs {
      width: 92px;
      img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
      }
      .edit-btn {
        cursor: pointer;
        margin-top: 24px;
        width: 72px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border: 1px solid #cdcdcd;
        border-radius: 4px;
      }
    }
    .msg {
      padding-top: 10px;
      flex: 1;
      & > div {
        margin-bottom: 10px;
      }
      .names {
        font-size: 16px;
        font-weight: 800;
      }
      .people {
        cursor: pointer;
        margin-left: 14px;
        color: #4387f8;
        svg {
          position: relative;
          top: 2px;
        }
      }
    }
    .header-info-flex {
      display: flex;
      justify-content: space-between;
    }
    .mgSelf {
      display: flex;
      justify-content: space-between;
      .icons {
        color: red;
        margin-left: 15px;
        cursor: pointer;
      }
      .iconq {
        margin-left: 15px;
      }
    }
    .flexBu {
      display: flex;
      justify-content: space-between;
      .qiye {
        font-size: 12px;
        color: #4387f8;
        cursor: pointer;
      }
      .icons {
        color: red;
        margin-left: 15px;
        cursor: pointer;
      }
      .ask {
        display: flex;
        & > div:nth-child(1) {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: rgba(248, 186, 67, 0.6);
          text-align: center;
          i {
            font-size: 16px;
            line-height: 28px;
            color: #fff;
          }
        }
        span {
          margin-left: 5px;
          line-height: 28px;
        }
        & > div:nth-child(3) {
          margin-left: 30px;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: rgba(67, 135, 248, 0.6);
          text-align: center;
          i {
            font-size: 16px;
            line-height: 28px;
            color: #fff;
          }
        }
      }
      .others {
        text-align: right;
        font-size: 12px;
        span:nth-child(2) {
          margin-left: 16px;
        }
      }
    }
  }
}

.titls {
  display: flex;
  justify-content: space-between;
  & > span:nth-child(1) {
    font-size: 16px;
    font-weight: 800;
  }

  & > span:nth-child(2) {
    font-size: 14px;
    color: #4387f8;
    user-select: none;
    cursor: pointer;
  }
}
.setMain {
  cursor: pointer;
  &:hover {
    color: #4387f8;
  }
}
.iconsd {
  cursor: pointer;
}
.blues {
  color: #4387f8;
}
</style>
<style lang="scss">
.dialog-header-line {
  .el-dialog__header {
    padding-bottom: 12px;
    border-bottom: 1px solid #d9d9df;
  }
}
</style>
