<template>
  <div v-if="type == 1" class="uploads">
    <el-upload
      :auto-upload="false"
      class="avatar-uploader"
      action="#"
      :show-file-list="false"
      :on-change="avatarChange"
    >
      <div class="change-img-box">
        <div v-if="upload_image" class="img-box">
          <img :src="upload_image" class="avatar-img" />
        </div>
        <div class="plus-box" v-else>
          <i class="el-icon-plus icon-plus"></i>
        </div>
      </div>
    </el-upload>
    <div @click="toggole(2)" class="scan-box" v-if="!upload_image">
      <img src="@/assets/img/login/QrIcon.png" class="icon-scan" />
      <div class="icon-code">扫码上传</div>
    </div>
  </div>
  <div v-else-if="type == 2" class="uploads">
    <img width="100" height="100" :src="showErweima" alt="" />
    <div @click="toggole(1)" class="scan-box" v-if="!upload_image">
      <img
        src="@/assets/img/login/phoneCode.png"
        alt="图片加载中"
        class="icon-scan"
      />
      <div class="icon-code">本地上传</div>
    </div>
  </div>
</template>

<script>
import { uploadFile } from "@/server/ajax.js";
export default {
  data() {
    return {
      upload_image: "",
      type: 1,
      ids: "",
      showErweima: "",
      loding: true,
    };
  },
  mounted() {},
  beforeDestroy() {
    this.loding = false;
    console.log(this.loding);
  },
  methods: {
    toggole(val) {
      this.type = val;
      if (val == 2) {
        this.ids = Math.random().toString(36).slice(2);

        this.$axios
          .post(
            "https://static." +
              VUE_APP_API_URL +
              "/api/files/code/gen",
            {
              client_id: this.ids,
              content: location.origin + "/h5/#/headPhoto?id=" + this.ids,
              img_size: "300",
            }
          )
          .then((res) => {
            this.showErweima = res.data;
            this.getPhone();
          });
      }
    },
    getPhone() {
      this.$axios
        .get(
          "https://static." +
            VUE_APP_API_URL +
            "/api/files/code/state",
          { client_id: this.ids }
        )
        .then((res) => {
          if (res.code == 200) {
            if (res.data == "uploading") {
              console.log(this.loding);
              setTimeout(() => {
                if (this.loding) {
                  this.getPhone();
                }
              }, 5000);
            } else if (res.msg == "上传成功") {
              const datas = JSON.parse(res.data);
              this.type = 1;
              this.upload_image = datas.first.image_url;
              this.$emit("succ", datas.first);
            }
          }
        });
    },
    avatarChange(files) {
      let fileUrl = "/api/files/create";
      uploadFile(fileUrl, {
        file: files.raw,
        is_public: 1,
      }).then((res) => {
        if (res.code == 200) {
          this.upload_image = res.data.image_url;
          this.$emit("succ", res.data);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.uploads {
  width: 100px;
  position: relative;
  &:hover .scan-box {
    display: block;
  }
  .scan-box {
    background-color: #fff;
    display: none;
    position: absolute;
    left: 70px;
    top: 0;
    cursor: pointer;
    .icon-scan {
      width: 30px;
      height: 30px;
    }
    &:hover {
      .icon-code {
        display: block;
      }
    }
    .icon-code {
      width: 80px;
      height: 30px;
      position: absolute;
      right: -92px;
      top: 0;
      z-index: 10;
      border-radius: 2px;
      border: 1px solid #f72626;
      background-color: #feecec;
      text-align: center;
      line-height: 30px;
      color: #f72626;
      display: none;
      z-index: 13;
      &::after {
        content: " ";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: calc(50% - 5px);
        left: -7px;
        border-top: 1px solid #f72626;
        border-right: 1px solid #f72626;
        background-color: #feecec;
        transform: rotate(-135deg);
      }
      &::before {
        content: " ";
        display: block;
        width: 15px;
        height: 30px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
      }
    }
  }
}

.change-img-box {
  position: relative;
  .plus-box {
    width: 100px;
    height: 100px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-plus {
      font-size: 30px;
    }
  }
  .img-box {
    width: 100px;
    height: 100px;
    .avatar-img {
      width: 100px;
      height: 100px;
    }
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  background-color: red;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
