<template>
  <el-dialog
    :visible.sync="avatarVisible"
    title="修改头像"
    width="640px"
    class="dialog-header-line"
  >
    <div class="change-avatar-box">
      <div class="left">
        <div class="left-title">选择本地照片</div>
        <div class="photo-box">
          <imgScan
            v-if="avatarVisible"
            @succ="imgSuccBtn"
            :key="avatarKey"
          ></imgScan>
          <div class="img-info">支持jpg、gif、png、bmp格式的图片，2M以内</div>
        </div>
      </div>
      <div class="right">
        <div class="right-title">头像预览</div>
        <div class="line-boxs">
          <div class="avatar-box">
            <img :src="show_avatar_url" class="img-avatar big" />
            <div class="img-info">120*120px</div>
          </div>
          <div class="avatar-box mt40">
            <img :src="show_avatar_url" class="img-avatar small" />
            <div class="img-info">64*64px</div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button class="btn" size="small" @click="avatarVisible = false"
        >取消</el-button
      >
      <el-button class="btn" size="small" type="danger" @click="saveAvatarBtn">
        保存头像</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import imgScan from "@/views/mySelf/components/imgScan.vue";
export default {
  components: {
    imgScan,
  },
  data() {
    return {
      avatarVisible: false,
      avatarKey: +new Date(),
      save_avatar_url: "",
      show_avatar_url: "",
      callFn: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    open(data, callFn) {
      this.show_avatar_url = data.img_url;
      this.save_avatar_url = "";
      this.avatarKey = +new Date();
      this.avatarVisible = true;
      this.callFn = callFn;
    },
    imgSuccBtn(data) {
      this.save_avatar_url = data.image_url;
      this.show_avatar_url = data.image_url;
    },
    saveAvatarBtn() {
      //保存头像
      if (!this.save_avatar_url) {
        return this.$message.error("请上传头像");
      }
      this.$axios
        .postJSON(this.$utils.ossImgUrl + "/api/user/headimg/save", {
          img_url: this.save_avatar_url,
        })
        .then((res) => {
          if (res.code == 200) {
            this.avatarVisible = false;
            this.$message.success("保存成功");
            this.callFn && this.callFn({ img_url: this.save_avatar_url });
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.change-avatar-box {
  display: flex;
  .left {
    width: 300px;
    padding-left: 48px;
    padding-top: 80px;
    .left-title {
      font-size: 14px;
      color: #666666;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 4px;
        height: 16px;
        background-color: #f74848;
        position: absolute;
        left: -10px;
        top: 0px;
      }
    }
    .photo-box {
      margin-top: 20px;
      .img-info {
        color: #666666;
        font-size: 12px;
        margin-top: 12px;
      }
    }
  }
  .right {
    text-align: center;
    width: 300px;
    .right-title {
      color: #666;
    }
    .line-boxs {
      border-left: 1px solid #efefef;
    }
    .avatar-box {
      margin-top: 16px;
      &.mt40 {
        margin-top: 40px;
      }
      .img-avatar {
        margin: 0 auto 12px;
        &.big {
          width: 120px;
          height: 120px;
        }
        &.small {
          width: 64px;
          height: 64px;
        }
      }
      .img-info {
        color: #666666;
        font-size: 12px;
      }
    }
  }
}
.dialog-footer {
  text-align: center;
  .btn {
    width: 80px;
  }
}
</style>
